import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Pages/Auth/Login"));
const ForgotPassword = React.lazy(() => import("./views/Pages/Auth/ForgotPassword"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const App = () => {
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response.status === 401) {
                window.localStorage.removeItem("nos-token");
                window.localStorage.removeItem("nos-role");
                window.location.href = "/login";
            }
            return Promise.reject(error);
        }
    );
    return (
        <HashRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                draggable={false}
            />
            <React.Suspense fallback={loading()}>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        name="Login"
                        render={props =>
                            localStorage.getItem("nos-token") !== null ? (
                                <>
                                    <DefaultLayout {...props} />
                                    <Redirect
                                        to={{
                                            pathname: "/",
                                        }}
                                    />
                                </>
                            ) : (
                                <Login {...props} />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/forgotPassword"
                        name="Forgot Password"
                        render={props => <ForgotPassword {...props} />}
                    />
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                    <Route
                        path="/"
                        render={props =>
                            localStorage.getItem("nos-token") !== null ? (
                                <DefaultLayout {...props} />
                            ) : (
                                <>
                                    <Login {...props} />
                                    <Redirect
                                        to={{
                                            pathname: "/login",
                                        }}
                                    />
                                </>
                            )
                        }
                    />
                </Switch>
            </React.Suspense>
        </HashRouter>
    );
};

export default App;
